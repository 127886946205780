const initData = (arr = [], other = '') => {
  const tempArr = arr.map((item, index) => ({
    code: `${index + 1}`,
    content: item
  }))
  if (other) {
    tempArr.push({
      code: '0',
      content: other
    })
  }
  return tempArr
}

export default {
  // 流程通用选项
  flow_common_select_ifEnable: [
    { code: '0', content: '否' },
    { code: '1', content: '是' }
  ],
  // 流程通用选项(中文)
  flow_common_select_ifEnable_cn: [
    { code: '否', content: '否' },
    { code: '是', content: '是' }
  ],
  // 城市等级
  city_level_name: [
    { code: '特级', content: '特级' },
    { code: '一级', content: '一级' },
    { code: '二级', content: '二级' },
    { code: '三级', content: '三级' },
    { code: '四级', content: '四级' },
    { code: '五级', content: '五级' },
    { code: '六级', content: '六级' }
  ],
  // ----------------------------
  // 学历
  education: [
    { code: '10', content: '小学' },
    { code: '11', content: '初中' },
    { code: '12', content: '高中' },
    { code: '13', content: '职高' },
    { code: '14', content: '中技' },
    { code: '15', content: '中专' },
    { code: '16', content: '大专' },
    { code: '17', content: '本科' },
    { code: '18', content: '硕士' },
    { code: '19', content: '博士' },
    { code: '20', content: 'MBA' },
    { code: '21', content: 'EMBA' },
    { code: '22', content: '博士后' },
    { code: '23', content: '其他' }
  ],
  // 婚姻状况
  marital_status: [
    { code: '1', content: '已婚' },
    { code: '2', content: '未婚' }
  ],
  // 性别
  sex: [
    { code: '1', content: '男' },
    { code: '2', content: '女' }
    // { code: "0", content: "未知" },
  ],
  // 经销商类型
  dealer_type: [
    { code: '1', content: '新经销商' },
    { code: '2', content: '老经销商' }
  ],

  // 紧急程度
  urgent_level: [
    { code: '1', content: '正常' },
    { code: '2', content: '紧急' }
  ],

  // 营业执照类型
  business_license_type: [
    { code: '0', content: '个体工商户' },
    { code: '1', content: '有限责任公司' }
  ],
  // 生效状态
  ifEnabled: [
    { code: '0', content: '生效' },
    { code: '1', content: '失效' }
  ],

  // 纳税类型
  pay_taxes_type: [
    { code: '0', content: '一般纳税人' },
    { code: '2', content: '小规模纳税人' },
    { code: '1', content: '定额纳税人' }
  ],

  // 专票/普票
  // bill_type: [
  //   { code: '1', content: '专票' },
  //   { code: '2', content: '普票' }
  // ],

  // 慕思品牌经营情况
  brand_management: [
    { code: '1', content: '有代理' },
    { code: '2', content: '无代理' }
  ],

  // 开店类型
  create_shop_type: [
    { code: '1', content: '新经销商开新区域' },
    { code: '2', content: '老经销商开新区域' },
    { code: '3', content: '老经销商开辟新区域' }
  ],

  // 开店类型
  create_store_type: [
    { code: '1', content: '经销商开店' },
    { code: '2', content: '商超网点' },
    { code: '3', content: '慕思美居开店' },
    { code: '4', content: '新渠道开店' }
  ],

  // 代理店的经营方式
  mode_of_operation: [
    { code: '1', content: '投资经营' },
    { code: '2', content: '全职经营' },
    { code: '3', content: '其他' }
  ],

  // 代理店的管理方式
  management_style: [
    { code: '1', content: '自己管理' },
    { code: '2', content: '亲属关联' },
    { code: '3', content: '招聘人员管理' },
    { code: '4', content: '其他方式' }
  ],

  // 门店规模
  store_size: initData(['MINI店', '标准店', '旗舰店']),

  // 合作关系
  partnership: [
    { code: '10', content: '直营' },
    { code: '20', content: '经销' },
    { code: '30', content: '零售' },
    { code: '40', content: '电商' },
    { code: '50', content: '工程' },
    { code: '60', content: '地产自营拎包' },
    { code: '70', content: '商超' },
    { code: '90', content: '公司间' }
  ],

  // 经营模式
  management_model: [
    { code: '10', content: '一级经销' },
    // { code: '10', content: '一级分销' },
    { code: '20', content: '二级分销' },
    // { code: '30', content: '三级分销' },
    { code: '40', content: '直营' }
  ],

  // 财务确认款项
  finance_confirm_status: [
    { code: '1', content: '到账' },
    { code: '2', content: '未到账' }
  ],
  // 是否装修
  renovation: initData(['装修'], '不装修'),
  // 门店状态
  shop_status: [
    {
      code: '9',
      content: '待设计'
    },
    {
      code: '15',
      content: '待装修'
    },
    {
      code: '10',
      content: '设计中'
    },
    {
      code: '20',
      content: '装修中'
    },
    {
      code: '30',
      content: '装修完成'
    },
    {
      code: '40',
      content: '正常营业'
    },
    {
      code: '45',
      content: '整改中'
    },
    {
      code: '50',
      content: '装修失效'
    },
    {
      code: '51',
      content: '系列变更失效'
    },
    {
      code: '60',
      content: '撤店完成'
    },
    {
      code: '70',
      content: '转让失效'
    },
    {
      code: '61',
      content: '人工失效'
    },
    {
      code: '62',
      content: '系列全失效引起门店失效'
    }
  ],
  // 送达方状态
  receiver_type: [
    {
      code: '10',
      content: '意向'
    },
    {
      code: '20',
      content: '临时授权'
    },
    {
      code: '30',
      content: '正式授权'
    },
    {
      code: '40',
      content: '待淘汰'
    },
    {
      code: '50',
      content: '终止合作'
    }
  ],
  // 是否关联方
  ifRelevance: [
    { code: '0', content: '否' },
    { code: '1', content: '是' }
  ],
  // 门店是否装修
  shop_if_decorate: [
    { code: '0', content: '否' },
    { code: '1', content: '是' }
  ],
  // 经销商性质
  join_nature: [
    { code: '0', content: '专一经营慕思' },
    { code: '1', content: '非专一经营慕思' }
  ],
  // 独立外立面门头标记
  independent_door_head_flag_boolean: [
    { code: true, content: '有' },
    { code: false, content: '没有' }
  ],
  // 直营公司
  direct_company: [
    { code: '东莞', content: '东莞' },
    { code: '深圳', content: '深圳' },
    { code: '广州', content: '广州' },
    { code: '上海', content: '上海' },
    { code: '重庆', content: '重庆' },
    { code: '北京崔佧', content: '北京崔佧' }
  ],
  // 资金处置情况
  asset_disposal: [
    { code: '1', content: '已处置' },
    { code: '0', content: '未处置' }
  ],
  // 人员处置情况
  person_disposal: [
    { code: '1', content: '辞退' },
    { code: '2', content: '安置其他店面' }
  ],
  // 角色名称
  role_code: [
    { code: '10', content: '实际控制人' },
    { code: '11', content: '法人' },
    { code: '12', content: '片区主管' },
    { code: '13', content: '大区经理' },
    { code: '14', content: '大区总监' },
    { code: '15', content: '订单主管' },
    { code: '16', content: '订单专员' },
    { code: '17', content: '招商专员' },
    { code: '18', content: '招商主管' },
    { code: '19', content: '招商总监' },
    { code: '20', content: '区域经理' },
    { code: '21', content: '招商经理' },
    { code: '22', content: '订单经理' }
  ],
  // 送达方状态
  ifEnabled_type: [
    { code: 0, content: '启用中' },
    { code: 1, content: '已停用' }
  ],
  // 经销商状态
  dealer_ifEnabled_type: [
    { code: 0, content: '启用' },
    { code: 1, content: '停用' }
  ],
  // 撤店原因
  shop_remove_code: [
    { code: 0, content: '商场原因' },
    { code: 1, content: '经销商经营原因' },
    { code: 2, content: '换位重装' },
    { code: 3, content: '系统数据修正' },
    { code: 4, content: '换商换位/换系列' },
    { code: 5, content: '店面换系列' }
  ],
  // 海外送达方标记
  overseas_flag: [
    { code: 1, content: '海外送达方' },
    { code: 0, content: '非海外送达方' }
  ],
  // 加盟状态
  join_status: [
    { content: '空白', code: 0 },
    { content: '转接', code: 1 },
    { content: '新系列', code: 2 }
  ],
  // 品牌类型
  classic_new_channel: [
    { code: '0', content: '新渠道（装企）' },
    { code: '1', content: '非新渠道（装企）' }
  ],
  // 装修风格
  decorate_style: [
    { code: '5.0风格', content: '5.0风格' },
    { code: '4.0风格', content: '4.0风格' },
    { code: '4.5风格', content: '4.5风格' },
    { code: '3.0风格', content: '3.0风格' },
    { code: '新渠道风格', content: '新渠道风格' },
    { code: '国际蓝者风格', content: '国际蓝者风格' },
    { code: '0769意简风格', content: '0769意简风格' }
  ]
}
