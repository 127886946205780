<template>
  <div class="flex">
    <el-select
      :value="value.key"
      style="width: 150px"
      placeholder="选择查询字段"
      clearable
      filterable
      @change="onConditionChange"
      @focus="fetchConditions">
      <el-option
        v-for="condition in conditions"
        :key="condition.code"
        :label="condition.content"
        :value="condition.code" />
    </el-select>
    <el-select
      :value="value.operation"
      style="width: 100px; margin: 0 4px"
      @change="onOperatorChange">
      <el-option
        v-for="item in operators"
        :key="item.value"
        :label="item.label"
        :value="item.value" />
    </el-select>
    <el-select
      v-if="
        [
          'brandCode',
          'independentDoorHeadFlag',
          'marketLevelName',
          'marketType',
          'mdcg',
          'multiLayoutType',
          'shopPositionLevel',
          'shopType',
          'storeOpeningMode',
          'oriPositionLevel',
          'strongEntryType',
          'decorationStopType',
          'storeOpenType',
          'if2023NewDealer',
          'subtype'
        ].includes(value.key)
      "
      v-model="subCondition"
      style="width: 205px"
      placeholder="请选择值"
      clearable
      @change="onSubConditionChange"
      @focus="onSubConditionFocus">
      <el-option
        v-for="condition in subConditions"
        :key="condition.code"
        :label="condition.content"
        :value="condition.code" />
    </el-select>
    <el-date-picker
      v-else-if="
        ['decorationTime', 'sampleOrderDate', 'decorationStopTime'].includes(
          value.key
        )
      "
      v-model="subCondition"
      style="width: 205px"
      type="datetime"
      value-format="yyyy-MM-dd HH:mm:ss"
      placeholder="选择日期时间"
      @change="onSubConditionChange" />
    <el-select
      v-else-if="['shopTypeValue'].includes(value.key)"
      style="width: 205px"
      placeholder="输入名称搜索"
      v-model="subCondition"
      filterable
      remote
      reserve-keyword
      clearable
      :remote-method="secondChannelRemoteMethod"
      @change="onSecondChannelChange"
      @focus="onSubConditionFocus">
      <el-option
        v-for="condition in subConditions"
        :key="condition.code"
        :label="condition.content"
        :value="condition.code" />
    </el-select>
    <el-input
      v-else
      v-model="subCondition"
      @change="onSubConditionInputChange"
      style="width: 205px"
      placeholder="请输入值" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { getDecorationSubsidyConditions } from '@/api/commonConfig/decorationSubsidy'
import { brandseriesList, datadictionaryList } from '@/api/common'

const props = defineProps({
  /**
   * @example
   * ```js
   * {
   *  operation:'',
   *  key:'',
   *  keyView:'',
   *  value:''
   *  valueView:'',
   * }
   * ```
   */
  value: {
    required: true,
    type: Object
  }
})
const emit = defineEmits(['update:value'])

const { conditions, fetchConditions } = useConditions()
async function onConditionChange(v) {
  const newValue = {
    operation: '',
    key: v,
    keyView: '',
    value: '',
    valueView: ''
  }
  const keyView = conditions.value.find(item => item.code === v)?.content
  newValue.keyView = keyView ?? v
  const hasEquation =
    operators.value.findIndex(item => item.value === '==') > -1
  if (hasEquation) {
    newValue.operation = '=='
  } else if (operators.value.length === 1) {
    newValue.operation = operators.value[0].value
  }

  emit('update:value', newValue)
}

const subCondition = ref('')
const subConditions = ref([])
onMounted(()=>{
  if(props.value.value){
    subCondition.value=props.value.value
    subConditions.value=[
      {
        content:props.value.valueView,
        code:props.value.value
      }
    ]
  }
})
async function onSubConditionFocus() {
  if (!props.value.key) {
    subConditions.value = []
    return
  }
  if (
    props.value.key === 'independentDoorHeadFlag' ||
    props.value.key === 'if2023NewDealer'
  ) {
    subConditions.value = [
      {
        code: '1',
        content: '是'
      },
      {
        code: '0',
        content: '否'
      }
    ]
    return
  }
  if (props.value.key === 'brandCode') {
    const res = await brandseriesList({
      brandLevel: 1
    })
    subConditions.value = res.data.map(item => ({
      id: item.id,
      code: item.code,
      content: item.name
    }))
    return
  }
  if (props.value.key === 'shopTypeValue') {
    if (!subCondition.value) {
      const res = await datadictionaryList({
        type: 'secondary_store_type',
        module: 'distributor',
        currPage: 1,
        pageSize: 25,
        content: ''
      })
      subConditions.value = res.data
    }
    return
  }
  const conditionItem = conditions.value.find(
    item => item.code === props.value.key
  )
  if (!conditionItem?.remark) {
    const cons = await getDecorationSubsidyConditions({
      code: conditionItem.code
    })
    const [con] = cons.filter(v => v.remark)
    if (!con) {
      subConditions.value = []
      return
    }
    conditionItem.remark = con.remark
  }
  subConditions.value = await getDecorationSubsidyConditions({
    type: conditionItem.remark
  })
}
function onSubConditionChange(v) {
  const valueView = subConditions.value.find(item => item.code === v)?.content
  emit('update:value', {
    ...props.value,
    value: v,
    valueView: valueView ?? v
  })
}
function onSubConditionInputChange(v) {
  const value = toNumber(v)
  subCondition.value = value
  emit('update:value', {
    ...props.value,
    value: value,
    valueView: value
  })
}

async function secondChannelRemoteMethod(text) {
  const res = await datadictionaryList({
    type: 'secondary_store_type',
    module: 'distributor',
    currPage: 1,
    pageSize: 25,
    content: text
  })
  subConditions.value = res.data
}
function onSecondChannelChange(code) {
  if (!code) {
    emit('update:value', {
      ...props.value,
      value: '',
      valueView: ''
    })
  } else {
    const condition = subConditions.value.find(item => item.code === code)
    emit('update:value', {
      ...props.value,
      value: condition.code,
      valueView: condition.content
    })
  }
}

const operators = useOperators()
function onOperatorChange(v) {
  emit('update:value', {
    ...props.value,
    operation: v
  })
}

function toNumber(value) {
  const trimValue = value.trim()
  if (trimValue === '') return ''
  const onlyNumber = trimValue.replace(/[^\d.]/g, '')
  if (Number.isNaN(Number(onlyNumber))) return ''
  return Number(onlyNumber).toPrecision()
}

function useConditions() {
  const conditions = ref([])
  onMounted(() => {
    if (props.value.key) {
      conditions.value = [
        {
          code: props.value.key,
          content: props.value.keyView
        }
      ]
    }
  })
  async function fetchConditions() {
    conditions.value = await getDecorationSubsidyConditions({
      type: 'costConfigType'
    })
  }
  return {
    conditions,
    fetchConditions
  }
}
function useOperators() {
  const allOperators = [
    {
      label: '包含',
      value: '包含'
    },
    {
      label: '大于',
      value: '>'
    },
    {
      label: '大于等于',
      value: '>='
    },
    {
      label: '等于',
      value: '=='
    },
    {
      label: '小于',
      value: '<'
    },
    {
      label: '小于等于',
      value: '<='
    }
  ]
  const operators = computed(() => {
    const isEquation = [
      'storeOpeningMode',
      'marketType',
      'shopType',
      'marketLevelName',
      'shopPositionLevel',
      'independentDoorHeadFlag',
      'mdcg',
      'multiLayoutType',
      'shopTypeValue',
      'oriPositionLevel',
      'strongEntryType',
      'decorationStopType',
      'storeOpenType',
      'if2023NewDealer',
      'subtype'
    ].includes(props.value.key)
    if (isEquation) {
      return allOperators.filter(v => v.value === '==')
    }
    if (props.value.key === 'brandCode') {
      return allOperators.filter(v => ['==', '包含'].includes(v.value))
    }
    return allOperators.filter(v =>
      ['>', '>=', '==', '<=', '<'].includes(v.value)
    )
  })
  return operators
}
</script>
