<template>
  <FlowForm
    ref="flowFormRef"
    :model="form"
    :get-detail="getDetail"
    :on-submit="onSubmit"
    :show-save="false"
    title="海外门店数据录入"
  >
    <HeaderTitle style="margin: 20px 0 10px" title="实控人信息" />
    <RequiredFormItemSelect
      ref="businessRef"
      v-model="form.businessLicenseId"
      class="formItem"
      prop="businessLicenseId"
      label="售达方"
      label-key="licenseNumber"
      value-key="id"
      filterable
      remote
      popper-class="custom-business-select"
      :get-data-list="getBusinessLicense"
      :get-next-list="getBusinessLicense"
      @onOptionsListNotFound="onNotFoundBusinessLicense"
      @change="
        () => {
          form.senderNumber = ''
          form.senderId = ''
          form.senderName = ''
        }
      "
      @changeSelectedItem="
        e => {
          businessSelectedItem = e
          form.businessLicenseName = e.businessLicenseName
          form.licenseNumber = e.licenseNumber
        }
      "
    >
      <template v-slot="{ scope }">
        <span>{{ scope.row.licenseNumber }}</span>
        <small>{{ scope.row.businessLicenseName }}</small>
      </template>
    </RequiredFormItemSelect>
    <RequiredFormItemInput
      class="formItem"
      label="售达方名称"
      placeholder="自动带出"
      disabled
    />
    <RequiredFormItemSelect
      v-model="form.senderNumber"
      class="formItem"
      prop="senderNumber"
      label="送达方编号"
      placeholder="请选择售达方下对应送达方"
      label-key="shopBrandNumber"
      value-key="shopBrandNumber"
      filterable
      remote
      :disabled="!form.businessLicenseId"
      :get-data-list="getMasterDataList"
      :next-data-list="getMasterDataList"
      :get-data-list-params="form.businessLicenseId"
      @onOptionsListNotFound="onNotFoundMasterDataList"
      @changeSelectedItem="
        e => {
          form.senderId = e ? e.id : ''
          form.senderName = e ? e.authorizedName : ''
        }
      "
    />
    <RequiredFormItemInput
      v-model="form.senderName"
      class="formItem"
      label="送达方名称"
      placeholder="系统自动带出"
      disabled
    />
    <RequiredFormItemSelect
      ref="shopRef"
      v-model="form.authorizedCityCode"
      class="formItem"
      prop="authorizedCityCode"
      label="授权城市"
      label-key="authorizedCityName"
      value-key="authorizedCityCode"
      empty-disabled
      :get-data-list="getCitylist"
      :get-next-list="getCitylist"
      :get-data-list-params="
        businessSelectedItem
          ? `${businessSelectedItem.id},${businessSelectedItem.bossId}`
          : ''
      "
      @changeSelectedItem="
        e => (form.authorizedCityName = e ? e.authorizedCityName : '')
      "
    />

    <br>
    <RequiredFormItemInput
      v-model="form.shopNumber"
      class="formItem"
      label="门店编号"
      placeholder="归档后自动生成"
      disabled
    />
    <RequiredFormItemInput
      v-model="form.shopName"
      prop="shopName"
      class="formItem"
      label="门店名称"
    />
    <RequiredFormItemInput
      v-model="form.marketName"
      prop="marketName"
      class="formItem"
      label="商场名称"
    />
    <RequiredFormItemInput
      v-model="form.street"
      class="formItem"
      label="门店地址"
      prop="street"
    />
    <RequiredFormItemInput
      v-model="form.shopArea"
      class="formItem"
      label="门店面积（平方米）"
      prop="shopArea"
    />
    <RequiredFormItemDatePicker
      v-model="form.openingTime"
      prop="openingTime"
      class="formItem"
      label="开业时间"
    />
    <br>

    <RequiredModuleFormItemSelect
      v-model="form.countryCode"
      prop="countryCode"
      module="public"
      type="country"
      class="formItem"
      label="国家"
      @change="
        () => {
          form.firstAdministrativeRegionCode = ''
          form.firstAdministrativeRegionName = ''
          form.secondAdministrativeRegionCode = ''
          form.secondAdministrativeRegionName = ''
        }
      "
      @changeSelectedItem="e => (form.country = e ? e.content : '')"
    />
    <RequiredModuleFormItemSelect
      v-model="form.firstAdministrativeRegionCode"
      module="public"
      type="province"
      :parent-code="form.countryCode"
      dependent-parent-code
      class="formItem"
      label="一级行政区"
      empty-disabled
      @change="
        () => {
          form.secondAdministrativeRegionCode = ''
          form.secondAdministrativeRegionName = ''
        }
      "
      @changeSelectedItem="
        e => (form.firstAdministrativeRegionName = e ? e.content : '')
      "
    />
    <RequiredModuleFormItemSelect
      v-model="form.secondAdministrativeRegionCode"
      module="public"
      type="city"
      :parent-code="form.firstAdministrativeRegionCode"
      dependent-parent-code
      class="formItem"
      label="二级行政区"
      empty-disabled
      @changeSelectedItem="
        e => (form.secondAdministrativeRegionName = e ? e.content : '')
      "
    />

    <RequiredFormItemSelect
      ref="applyBrandSeriesListRef"
      :value="form.applySeriesList.map(item => item.code)"
      class="formItem"
      label-key="name"
      value-key="brandCode"
      label="门店代理系列"
      multiple
      empty-disabled
      :get-data-list-params="`${form.businessLicenseId || ''},${
        form.authorizedCityCode || ''
      }`"
      :get-data-list="getBrandseriesList"
      @change="changeApplyBrandSeriesListSelectedItem"
    />

    <RequiredModuleFormItemSelect
      v-model="form.shopType"
      prop="shopType"
      type="store_type"
      class="formItem"
      label="渠道性质"
      @changeSelectedItem="e => (form.shopTypeValue = e ? e.content : '')"
    />
    <RequiredModuleFormItemSelect
      v-model="form.statusExplain"
      prop="statusExplain"
      type="shop_status"
      class="formItem"
      label="门店状态"
    />
  </FlowForm>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import * as BusinessApi from '@/api/attractPlatform/business'
import * as dealerJoinApi from '@/api/attractPlatform/dealerJoinApi'
import {
  getDealerBrand,
  getMasterDataList,
  getBusinessCity
} from '@/api/businessOos/index'
import { saveShop } from './overseasDataApi'

import FlowForm from './components/FlowForm'
import HeaderTitle from '@/views/flow/components/headerTitle'

import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'

export default {
  components: {
    FlowForm,
    HeaderTitle,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredModuleFormItemSelect,
    RequiredFormItemDatePicker
  },
  data() {
    return {
      form: {
        businessLicenseId: '', //	是	strng	售达方id
        businessLicenseName: '', //	是	strng	售达方名称
        licenseNumber: '', //	是	strng	售达方编号
        shopNumber: '', //	是	strng	门店编号
        shopName: '', //	是	strng	门店名称
        marketName: '', //	是	strng	商场名称
        street: '', //	是	strng	门店地址
        shopArea: '', //	是	strng	门店面积（平方米）
        openingTime: '', //	是	strng	开业时间
        country: '', //	是	strng	国家
        countryCode: '', //	是	strng	国家编码
        firstAdministrativeRegionCode: '', //	是	strng	一级行政区域编码
        firstAdministrativeRegionName: '', //	是	strng	一级行政区域名称
        secondAdministrativeRegionCode: '', //	是	strng	二级行政区域编码
        secondAdministrativeRegionName: '', //	是	strng	二级行政区域名称
        shopType: '', //	是	strng	渠道性质编码
        shopTypeValue: '', //	是	strng	渠道性质中文
        statusExplain: '', //	是	strng	门店状态 9:待设计,10:设计中,20:装修中,30:装修完成,40:正常营业,50:装修失效,60:撤店失效,61:人工失效,62:系列全失效引起门店失效,70:转让失效
        createUsername: '', //	是	strng	创建人名字
        createUserId: '', //	是	strng	创建人id
        applySeriesList: [], //	是	数组对象	小品牌列表
        senderId: '', //	是	strng	送达方Id
        senderNumber: '', //	是	strng	送达方编号
        senderName: '', //	是	strng	送达方名称
        authorizedCityCode: '',
        authorizedCityName: ''
      },
      businessSelectedItem: null
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState({ isOA: state => state.dealershipTransfer.isOA })
  },
  methods: {
    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await dealerJoinApi.detail({
          formId: this.$route.query.id
        })
        initFormData = data
      }
      if (initFormData) {
        for (const key in this.form) {
          if (initFormData[key] !== undefined) {
            this.form[key] = initFormData[key]
          }
        }
      }
      if (this.userInfo && !this.isOA) {
        const { createUserId, createUserName, ...rest } = this.form || {}
        this.form = {
          ...rest,
          createUserId: createUserId || this.userInfo.accountId,
          createUserName: createUserName || this.userInfo.realName
        }
      }
    },
    getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundBusinessLicense({ value: businessLicenseId }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId
        }).then(res => res.data)
      )
    },
    getBrandseriesList({ getDataListParams, ...params }) {
      const [businessLicenseId, authorizedCityCode] =
        getDataListParams.split(',')
      if (!businessLicenseId || !authorizedCityCode) return []
      return getDealerBrand({
        businessLicenseId,
        authorizedCityCode
      }).then(res => res.data)
    },
    changeApplyBrandSeriesListSelectedItem() {
      this.$nextTick(() => {
        const selectItems =
          this.$refs.applyBrandSeriesListRef.$selectRef.selectedItem || []
        this.form.applySeriesList = selectItems.map(item => ({
          id: item.brandId,
          code: item.brandCode,
          name: item.name
        }))
      })
    },
    getMasterDataList({ getDataListParams: businessLicenseId, searchText }) {
      // const [businessLicenseId] = getDataListParams
      if (!businessLicenseId) {
        return []
      }
      return getMasterDataList({
        businessLicenseId,
        // authorizedCityCode,.split(',')
        shopBrandNumber: searchText
      })
    },
    async onNotFoundMasterDataList(e, callback) {
      callback(
        await getMasterDataList({
          shopBrandNumber: this.form.senderNumber
        }).then(res => res.data)
      )
    },
    getCitylist({
      getDataListParams,
      page: currPage,
      searchText: authorizedCityName
    }) {
      if (!getDataListParams) {
        return []
      }
      const [businessLicenseId, bossId] = getDataListParams.split(',')
      return BusinessApi.shoplist({
        businessLicenseId,
        bossId,
        currPage,
        authorizedCityName,
        pageSize: 10
      })
    },
    onSubmit() {
      return saveShop(this.form)
    }
  }
}
</script>

<style></style>
