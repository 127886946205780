<script setup>
import TablePro from '@/components/TablePro'
import { getProductList } from './api/'

const tableProProps = {
  request: getProductList,
  autoWidthCell: true,
  showIndex: true,
  columns: [
    { title: '酒店ID', name: 'hotelId' },
    { title: '酒店名称', name: 'hotelName', search: true },
    { title: '房间名称', name: 'roomName' },
    { title: '房型', name: 'roomType' },
    { title: '设备名称', name: 'deviceName' },
    { title: '设备码', name: 'deviceCode' },
    {
      title: '设备状态',
      name: 'status',
      search: true,
      valueType: 'select',
      valueEnum: [
        { label: '在线', value: 1 },
        { label: '离线', value: 0 }
      ]
    }
  ]
}
</script>

<template>
  <TablePro v-bind="tableProProps" />
</template>
