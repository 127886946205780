import {
  login,
  logout,
  getInfo,
  getBusiness,
  authLogin,
  menuList,
  ssologinEntry,
  accountloginTempcode,
  authorizationLogin
} from '@/api/user'
import {
  getStorage,
  setStorage,
  setSession,
  getSession,
  removeStorage
} from '@/utils/storage'
import { resetRouter } from '@/router'
import md5 from 'js-md5'
import { Message } from 'element-ui'

const userInfo = getStorage('userInfo') || {}

const getDefaultState = () => {
  return {
    token: '',
    userInfo: userInfo,
    business: getStorage('business') || [],
    systemCode: getSession('systemCode') || process.env.VUE_APP_SYSTEM_CODE,
    menuList: userInfo.menuList || [],
    ssoToken:getStorage('ssoToken') || '',
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, data) => {
    state.userInfo = data
  },
  SET_BUSINESS: (state, data) => {
    state.business = data
  },
  SET_SYSTEM_CODE: (state, data) => {
    state.systemCode = data
    setSession('systemCode', data)
  },
  SET_MENU_LIST(state, list) {
    state.menuList = list
  },
  SET_SSO_TOKEN:(state,data) =>{
    state.ssoToken = data
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    commit('SET_SYSTEM_CODE', process.env.VUE_APP_SYSTEM_CODE)
    return new Promise((resolve, reject) => {
      login({
        account: username.trim(),
        password: md5(password),
        systemCode: process.env.VUE_APP_SYSTEM_CODE
      })
        .then(response => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setStorage('token', data.token)
          setStorage('account', {
            account: username,
            accountId: data.id,
            password
          })
          resolve()
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  //auth login 授权登录
  authLogin({ commit }, commitData) {
    if (!commitData.code) {
      commit('SET_SYSTEM_CODE', process.env.VUE_APP_OA_SYSTEM_CODE)
    }
    return new Promise(async (resolve, reject) => {
      const params = commitData.code
        ? commitData
        : { ...commitData, systemCode: process.env.VUE_APP_OA_SYSTEM_CODE }
      const loginEntry = commitData.code ? ssologinEntry : authLogin
      loginEntry(params)
        .then(response => {
          const { data } = response
          data.code = data.account
          commit('SET_TOKEN', data.token)
          setStorage('token', data.token)
          if (commitData.code) {
            setStorage('token', data.token)
          }
          if(data.ssoToken) {
            setStorage('ssoToken',data.ssoToken)
            commit('SET_SSO_TOKEN',data.ssoToken)
          }
          data.menuList?.length && commit('SET_MENU_LIST', data.menuList)
          setStorage('userInfo', data)
          commit('SET_USERINFO', data)
          setStorage('account', { accountId: data.id })
          resolve()
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  //临时code登录
  async tempCodeLogin({ commit }, data) {
    try {
      const {
        data: { tempCode }
      } = await accountloginTempcode({
        systemCode: '10026',
        account: data.account
      })
      const { data: userInfo } = await authorizationLogin({
        systemCode: '10026',
        tempCode
      })
      console.log('authorizationLogin', userInfo)

      commit('SET_TOKEN', userInfo?.token)
      setStorage('token', userInfo?.token)
      setStorage('account', {
        accountId: userInfo?.id,
        account: userInfo?.account
      })
      return Promise.resolve(userInfo)
    } catch (e) {
      console.log(e)
      Message.error('登录失败')
    }
  },
  // get user info
  getUserInfo({ commit }, state) {
    const systemCode =
      getSession('systemCode') || process.env.VUE_APP_SYSTEM_CODE
    const token = getStorage('token') || {}

    return new Promise((resolve, reject) => {
      getInfo({ apiToken: token, systemCode })
        .then(response => {
          const { data } = response
          if (!data) return reject('请重试')
          data.account = data.code
          setStorage('userInfo', data)
          commit('SET_USERINFO', data)
          commit('SET_MENU_LIST', data.menuList)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit }) {
    removeStorage('token')
    removeStorage('userInfo')
    removeStorage('account')
    removeStorage('business')
    commit('RESET_STATE')
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },
  getBusinessData({ commit }) {
    return new Promise((resolve, reject) => {
      getBusiness()
        .then(res => {
          setStorage('business', res.data)
          commit('SET_BUSINESS', res.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  //获取路由菜单
  getMenuList({ commit }) {
    menuList({ system_id: process.env.VUE_APP_SYSTEM_CODE }).then(res => {
      commit('SET_MENU_LIST', res.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
