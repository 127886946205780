// 根据门店性质筛选品牌
export const storeNatureBrandListFilter = (list, storeNature, brandList) => {
  console.log(storeNature)
  switch (Number(storeNature)) {
    // 综合店
    case 10:
    // 融合店
    case 11: //寝具综合店
    case 12: // 沙发综合店
    case 13: // 客餐卧综合店
    case 60: // 整装店
    case 30:
    case 70: // 羡慕融合店
    case 80: // 寝具店
    case 90: // 大家居店
    case 100: // 全屋宅配店
      return list
    // 品牌店
    case 20:
    case 21: // 寝具单系列店
    case 22: //沙发单系列店
    {
      // 助眠系列: 1390119723531939841, 芯睡眠: 1390119723603243010;
      const plugList = ['1390119723531939841', '1390119723603243010']
      const len = brandList.length

      if (len >= 1) {
        // 是否选中其他的项
        const isSelected = !brandList.every(item => plugList.includes(item))
        if (isSelected) {
          return list.filter(item => {
            return (
              brandList.includes(item.brandId || item.id) ||
              plugList.includes(item.brandId || item.id)
            )
          })
        }
      }
      return list
    }
    // 融合店
    // case 30:
    //   // 排除
    //   return list.filter(item => {
    //     return ![
    //       "慕思助眠",
    //       "慕思家纺",
    //       "崔佧",
    //       "思丽德赛-国际",
    //       "思丽德赛-简约",
    //       "V6家居",
    //       "慕思时尚-V6"
    //     ].includes(item.name);
    //   });
    // V6寝具店
    // V6沙发店
    case 40:
    case 50:
      // 只能选
      // V6、V6家居
      return list.filter(item => {
        return ['慕思时尚-V6', 'V6家居'].includes(item.brandName || item.name)
      })
    // 如果没有门店性质,则返回已选中的品牌
    default:
      return list.filter(item => {
        return brandList.includes(item.id)
      })
  }
}
