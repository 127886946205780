<template>
  <div>
    <FlowForm
      ref="flowFormRef"
      title="海外经销商加盟流程"
      fd-form-template-code="distributor-JXS035"
      :model="form"
      :is-edit="[0, 20].includes(Number(form.status))"
      :get-detail="getDetail"
      :on-submit="onSubmit"
      :approval-data="form.approveData"
      :on-approval="onApproval">
      <div>
        <RequiredFormItemInput
          delayUpdate
          label="标题"
          class="formItem"
          :value="form.title"
          disabled
          :required="false" />
        <RequiredFormItemInput
          delayUpdate
          label="单号"
          class="formItem"
          :value="form.orderNumber"
          placeholder="自动生成"
          disabled
          :required="false" />
        <RequiredFormItemInput
          delayUpdate
          label="招商单号"
          class="formItem"
          :value="form.zsOrderNumber"
          placeholder="自动生成"
          disabled
          :required="false" />
        <RequiredFormItemInput
          class="formItem"
          label="提单人工号"
          :value="form.optJobNumber"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="提单人"
          :value="form.optUserName"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="提单部门"
          :value="form.optDeptName"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="实控人编码"
          :value="form.bossNumber"
          placeholder="归档后生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="售达方"
          :value="form.businessLicenseName"
          placeholder="归档后生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="送达方"
          :value="form.authorizedBrandName"
          placeholder="归档后生成"
          disabled />
        <HeaderTitle style="margin-top: 20px" title="基础信息" />
        <RequiredFormItemInput
          class="formItem"
          label="品牌"
          :value="
            form.applySeriesList.length ? form.applySeriesList[0].name : ''
          "
          disabled />
        <RequiredFormItemInput
          prop="customerSellName"
          class="formItem"
          label="客户名称（售达方名称）"
          @input="getBusiness"
          v-model="form.customerSellName" />
        <RequiredFormItemInput
          prop="customerSellShortName"
          class="formItem"
          label="客户简称（售达方简称）"
          :disabled="isDisabledEdit"
          v-model="form.customerSellShortName" />
        <RequiredFormItemInput
          prop="customerAddress"
          class="formItem"
          label="客户地址（售达方地址）"
          :disabled="isDisabledEdit"
          v-model="form.customerAddress" />
        <RequiredFormItemInput
          prop="customerDeliveryShortName"
          class="formItem"
          label="客户简称（送达方简称）"
          v-model="form.customerDeliveryShortName" />
        <RequiredFormItemInput
          prop="customerDeliveryAddress"
          class="formItem"
          label="客户地址（送达方地址）"
          v-model="form.customerDeliveryAddress" />
        <RequiredModuleFormItemSelect
          v-model="form.countryCode"
          module="public"
          type="country"
          prop="countryCode"
          class="formItem"
          label="国家"
          :disabled="isDisabledEdit"
          @change="
            () => {
              form.firstAdministrativeRegionCode = ''
              form.firstAdministrativeRegionName = ''
              form.secondAdministrativeRegionCode = ''
              form.secondAdministrativeRegionName = ''
            }
          "
          @changeSelectedItem="e => (form.countryName = e?.content)" />
        <RequiredModuleFormItemSelect
          v-model="form.firstAdministrativeRegionCode"
          dependent-parent-code
          empty-disabled
          :parent-code="form.countryCode"
          module="public"
          type="province"
          prop="firstAdministrativeRegionCode"
          class="formItem"
          label="一级行政区域"
          :disabled="isDisabledEdit"
          @change="
            () => {
              form.secondAdministrativeRegionCode = ''
              form.secondAdministrativeRegionName = ''
            }
          "
          @changeSelectedItem="
            e => (form.firstAdministrativeRegionName = e?.content)
          " />
        <RequiredModuleFormItemSelect
          v-model="form.secondAdministrativeRegionCode"
          dependent-parent-code
          empty-disabled
          :parent-code="form.firstAdministrativeRegionCode"
          module="public"
          type="city"
          prop="secondAdministrativeRegionCode"
          class="formItem"
          label="二级行政区域"
          :disabled="isDisabledEdit"
          @changeSelectedItem="
            e => (form.secondAdministrativeRegionName = e?.content)
          " />
        <RequiredFormItemInput
          prop="postcode"
          class="formItem"
          label="邮政编码"
          :disabled="isDisabledEdit"
          v-model="form.postcode" />
        <RequiredFormItemInput
          prop="legalPerson"
          class="formItem"
          label="实控人名称（法人）"
          :disabled="isDisabledEdit"
          v-model="form.legalPerson" />
        <RequiredFormItemInput
          prop="legalPersonPhone"
          class="formItem"
          label="实控人电话（法人）"
          :disabled="isDisabledEdit"
          v-model="form.legalPersonPhone" />
        <RequiredFormItemInput
          prop="legalPersonEmail"
          class="formItem"
          label="实控人邮箱（法人）"
          :disabled="isDisabledEdit"
          v-model="form.legalPersonEmail" />
        <RequiredFormItemInput
          prop="contactName"
          class="formItem"
          label="联系人名称"
          :disabled="isDisabledEdit"
          v-model="form.contactName" />
        <RequiredFormItemInput
          prop="contactPhone"
          class="formItem"
          label="联系人电话"
          :disabled="isDisabledEdit"
          v-model="form.contactPhone" />
        <RequiredFormItemInput
          prop="contactEmail"
          class="formItem"
          label="联系人邮箱"
          v-model="form.contactEmail" />
        <RequiredModuleFormItemSelect
          v-model="form.customerSourceCode"
          type="dealer_source"
          prop="customerSourceCode"
          class="formItem"
          label="客户来源"
          :disabled="isDisabledEdit"
          @changeSelectedItem="e => (form.customerSource = e?.content)" />
        <HeaderTitle style="margin-top: 20px" title="合作信息" />
        <RequiredFormItemSelect
          prop="authorizedCityCode"
          class="formItem"
          label="授权城市"
          v-model="form.authorizedCityCode"
          label-key="authorizedCityName"
          value-key="authorizedCityCode"
          remote
          filterable
          :getDataList="getAuthorizedCitiesDataList"
          @onOptionsListNotFound="onAuthorizedCityNotFound"
          @changeSelectedItem="
            e => {
              form.authorizedCityName = e?.authorizedCityName
            }
          " />
        <RequiredModuleFormItemSelect
          v-model="form.transactionTermsCode"
          type="exchange_provision"
          prop="transactionTermsCode"
          class="formItem"
          label="交易条款"
          :disabled="isDisabledEdit"
          @changeSelectedItem="e => (form.transactionTerms = e?.content)" />
        <RequiredModuleFormItemSelect
          v-model="form.paymentMethodCode"
          type="settlement_type"
          prop="paymentMethodCode"
          class="formItem"
          label="付款方式"
          @changeSelectedItem="e => (form.paymentMethod = e?.content)" />
        <RequiredFormItemInput
          class="formItem"
          label="统驭科目"
          disabled
          v-model="form.accountSubject" />
        <RequiredFormItemInput
          prop="bankName"
          class="formItem"
          label="开户行名称"
          :disabled="isDisabledEdit"
          v-model="form.bankName" />
        <RequiredFormItemInput
          prop="bankCode"
          class="formItem"
          label="银行代码（Swift）"
          v-model="form.bankCode" />
        <RequiredFormItemInput
          prop="bankAccount"
          class="formItem"
          label="银行账号"
          :disabled="isDisabledEdit"
          v-model="form.bankAccount" />
        <RequiredFormItemInput
          class="formItem"
          label="交易币别"
          :value="form.transactionCurrency"
          disabled />
        <RequiredFormItemSelect
          class="formItem"
          prop="deposit"
          label="是否有保证金"
          v-model="form.deposit"
          label-key="label"
          value-key="value"
          :dataList="[
            { label: '是', value: '1' },
            { label: '否', value: '0' }
          ]" />
        <RequiredFormItemSelect
          v-model="form.businessManagerJobNumber"
          :get-data-list="getUserList"
          prop="businessManagerJobNumber"
          class="formItem"
          label="业务负责人"
          value-key="account"
          :label-key="({ username, account }) => `${username}（${account}）`"
          filterable
          remote
          clearable
          @onOptionsListNotFound="onNotFoundUserList"
          @onChangeItem="
            selectItem => {
              form.businessManager = selectItem?.username
            }
          " />
        <RequiredFormItemInput
          v-model="form.businessManagerJobNumber"
          disabled
          class="formItem"
          label="业务负责人工号" />
        <RequiredFormItemDatePicker
          v-model="form.customerCooperationTime"
          prop="customerCooperationTime"
          class="formItem"
          label="客户合作时间" />
        <HeaderTitle style="margin-top: 20px" title="其他信息" />
        <RequiredFormItemSelect
          class="formItem"
          prop="cooperationSituation"
          label="与慕思的合作情况（现阶段是否在其他业务/其他有和慕思合作）"
          v-model="form.cooperationSituation"
          label-key="label"
          value-key="value"
          :dataList="[
            { label: '是', value: '1' },
            { label: '否', value: '0' }
          ]" />
        <RequiredFormItemInput
          class="formItem"
          prop="reason"
          label="事由说明"
          v-model="form.reason" />
        <RequiredFormItemUploadFile
          prop="businessLicenseFile"
          v-model="form.businessLicenseFile"
          class="formItem"
          limit-size="400"
          label="营业执照照片/电子档（商业登记证）"
          auto-upload>
          <template v-slot:tip>
            <div>单个文件不能超过400M</div>
          </template>
        </RequiredFormItemUploadFile>
        <RequiredFormItemUploadFile
          prop="fullNameOfBank"
          v-model="form.fullNameOfBank"
          class="formItem"
          limit-size="400"
          label="开户证明或者网银截图(能显示开户银行全称)"
          auto-upload>
          <template v-slot:tip>
            <div>单个文件不能超过400M</div>
          </template>
        </RequiredFormItemUploadFile>
        <RequiredFormItemUploadFile
          prop="newBusinessContract"
          v-model="form.newBusinessContract"
          class="formItem"
          limit-size="400"
          label="新商合同"
          auto-upload>
          <template v-slot:tip>
            <div>单个文件不能超过400M</div>
          </template>
        </RequiredFormItemUploadFile>
      </div>
    </FlowForm>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, toRefs, nextTick } from 'vue'
import dateFormat from 'dateformat'
import FlowForm from '@/views/bpmFlow/components/flowForm.vue'
import {
  RequiredFormItemInput,
  RequiredFormItemSelect,
  RequiredFormItemUploadFile,
  RequiredModuleFormItemSelect,
  RequiredFormItemDatePicker
} from '@/components/CustomFormItem'
import HeaderTitle from '@/views/bpmFlow/components/headerTitle.vue'
import store from '@/store'
import { useRoute, useRouter } from '@/utils/router'
import { requestGetUserList, brandseriesList } from '@/api/common'
import { authorizedcityListpage, getBusinessDataList } from '@/api/businessOos'
import * as overseasDealersJoinApi from '@/views/bpmFlow/api/overseasDealersJoinApi'
import { debounce } from 'throttle-debounce'
const $route = useRoute()
const routeQuery = $route.query
const flowFormRef = ref()
const user = store.state.user.userInfo
const isOA = computed(() => store.state.dealershipTransfer.isOA)
const isMobile = computed(
  () => store.state.app.device === 'mobile' || store.state.app.isWxworkMoblie
)
const isDisabledEdit = ref(true)
const form = reactive({
  id: '', //表单id
  title: '海外经销商加盟申请表', //标题
  orderNumber: '', //单号
  zsOrderNumber: '', //招商单号
  optJobNumber: '', //提单人工号
  optUserId: '', //提单人id
  optUserName: '', //提单人
  optDeptId: '', //提单部门id
  optDeptName: '', //提单部门
  optTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss'), //提单日期
  overseasFlag: 1, //是否海外流程 0否1是
  bossNumber: '', //实控人编码
  businessLicenseName: '', //售达方名称
  authorizedBrandName: '', //送达方名称
  applySeriesList: [], //品牌
  customerSellName: '', //客户名称（售达方名称）
  customerSellShortName: '', //客户简称（售达方简称）
  customerAddress: '', //客户地址（售达方地址）
  customerDeliveryShortName: '', // 客户简称 (送达方简称）
  customerDeliveryAddress: '', // 客户地址 (送达方地址）
  countryCode: '', //国家编码
  countryName: '', //国家名字
  firstAdministrativeRegionCode: '', // 一级行政区域编码
  firstAdministrativeRegionName: '', // 一级行政区域名称
  secondAdministrativeRegionCode: '', // 二级行政区域编码
  secondAdministrativeRegionName: '', // 二级行政区域名称
  postcode: '', // 邮政编码
  legalPerson: '', // 实控人名称（法人）
  legalPersonPhone: '', // 实控人电话 (法人)
  legalPersonEmail: '', // 实控人邮箱 (法人)
  contactName: '', //联系人名称
  contactPhone: '', //联系人电话
  contactEmail: '', //联系人邮箱
  customerSourceCode: '', //客户来源编码
  customerSource: '', //客户来源
  authorizedCityCode: '', //授权城市编码
  authorizedCityName: '', //授权城市名称
  transactionTermsCode: '', //交易条款编码
  transactionTerms: '', //交易条款
  paymentMethodCode: '', //付款方式编码
  paymentMethod: '', //付款方式
  accountSubject: '11220102 应收账款-集团外-国外', //统驭科目
  bankName: '', //开户行名称
  bankCode: '', //银行代码（Swift）
  bankAccount: '', //银行账号
  transactionCurrency: '美元', //交易币别
  deposit: '', //是否有保证金
  businessManager: '', //业务负责人
  businessManagerJobNumber: '', //业务负责人工号
  customerCooperationTime: '', //客户合作时间
  cooperationSituation: '', //与慕思的合作情况（现阶段是否在其他业务/其他有和慕思合作）
  reason: '', //事由说明
  businessLicenseFile: [], //营业执照照片/电子档（商业登记证）
  fullNameOfBank: [], //开户证明或者网银截图(能显示开户银行全称)
  newBusinessContract: [], //新商合同
  approveData: {}, //bpm审批字段
  applyOrderModel: {} //bpm必填
})
const getBusiness = debounce(100, async e => {
  if (!e) return
  const res = await getBusinessDataList({
    businessLicenseName: e
  })
  isDisabledEdit.value = res.data.length == 1
  if (res.data.length !== 1) {
    return
  }
  const {
    businessLicenseShortName = '',
    businessLicenseAddress = '',
    countryCode = '',
    firstAdministrativeRegionCode = '',
    secondAdministrativeRegionCode = '',
    postCode = '',
    legalPersonName = '',
    legalPersonContact = '',
    eMail = '',
    contactPersonName = '',
    contactPersonPhone = '',
    customerOriginCode = '',
    tradingItem = '',
    reconciliationAccount = '',
    bank = '',
    bankAccount = ''
  } = res.data[0]
  form.customerSellShortName = businessLicenseShortName
  form.customerAddress = businessLicenseAddress
  form.countryCode = countryCode
  form.firstAdministrativeRegionCode = firstAdministrativeRegionCode
  form.secondAdministrativeRegionCode = secondAdministrativeRegionCode
  form.postcode = postCode
  form.legalPerson = legalPersonName
  form.legalPersonPhone = legalPersonContact
  form.legalPersonEmail = eMail
  form.contactName = contactPersonName
  form.contactPhone = contactPersonPhone
  form.customerSourceCode = customerOriginCode
  form.transactionTermsCode = tradingItem
  form.accountSubject = reconciliationAccount
  form.bankName = bank
  form.bankAccount = bankAccount
})
const getBrandList = async () => {
  const res = await brandseriesList({ code: '24', brandLevel: '1' })
  form.applySeriesList = res.data.map(v => {
    return {
      id: v.id,
      code: v.code,
      name: v.name
    }
  })
  return res.data
}
const getAuthorizedCitiesDataList = async ({ page, searchText }) => {
  const res = await authorizedcityListpage({
    currPage: page,
    overseasFlag: 1,
    authorizedCityName: searchText,
    ifEnabled: 0
  })
  return res.data
}
const onAuthorizedCityNotFound = async (
  { value: authorizedCityCode },
  callback
) => {
  const res = await authorizedcityListpage({
    currPage: 1,
    authorizedCityCode
  })
  callback(res.data)
}
const getUserList = async ({ page, searchText: searchVal }) => {
  return requestGetUserList({
    page,
    limit: 10,
    searchVal
  }).then(res => {
    const { list, ...result } = res.page
    return { data: list, ...result }
  })
}
const onNotFoundUserList = async ({ value: searchVal }, callback) => {
  callback(
    await requestGetUserList({
      searchVal
    }).then(res => res.page.list)
  )
}
const onApproval = async (bpmExecuteBean, applyOrderModel) => {
  // console.log('审批',bpmExecuteBean, applyOrderModel)
  if (Number(form.status) === 20) {
    form.applyOrderModel = {
      ...form.applyOrderModel,
      ...applyOrderModel,
      bpmExecuteBean
    }
    return flowFormRef.value.onCommit('submit')
  }

  return overseasDealersJoinApi.submitApproveData({
    id: routeQuery.id || routeQuery.businessId,
    optUserId: user.accountId,
    optUserName: user.realName,
    optJobNumber: user.code || user.account,
    approveData: form.approveData,
    applyOrderModel: {
      ...form.applyOrderModel,
      ...applyOrderModel,
      approveAccount: user.code || user.account,
      bpmExecuteBean
    }
  })
}
const getDetail = async (type, cachedFormData) => {
  let initFormData = cachedFormData
  if (type === 'create') {
    await getBrandList()
  }
  // 编辑状态
  if (type === 'detail') {
    const { data } = await overseasDealersJoinApi.getDetail({
      formId: routeQuery.id || routeQuery.businessId
    })
    initFormData = data
  }

  if (initFormData) {
    for (const key in form) {
      if (initFormData[key] !== undefined) {
        form[key] = initFormData[key]
      }
    }
  }

  if (user && !isOA.value) {
    form.optUserId = form.optUserId || user.accountId
    form.optJobNumber = form.optJobNumber || user.code
    form.optUserName = form.optUserName || user.realName
    form.optDeptId = form.optDeptId || user.deptId
    form.optDeptName = form.optDeptName || user.deptName
  }
}
const onSubmit = async type => {
  const time = dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss')
  form.optTime = time
  form.applyOrderModel.approveAccount = user.code || user.account
  form.applyOrderModel.fdFormTemplateCode = 'distributor-JXS035'
  form.applyOrderModel.pageVersion = 'V1'
  form.applyOrderModel.systemCode = 'distributor'

  form.title = `海外经销商加盟申请表(${form.authorizedCityName || ''},${
    form.applySeriesList.length > 0 ? form.applySeriesList[0].name : ''
  })`
  if (type !== 'submit') {
    return overseasDealersJoinApi.saveDraft(form)
  }
  return overseasDealersJoinApi.submit(form)
}
</script>

<style lang="scss"></style>
