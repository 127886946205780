<template>
  <el-drawer
    title="修改详情"
    :visible.sync="storeVisibleDialog"
    :destroy-on-close="true"
    @close="handleClose"
    direction="rtl"
    size="50%;overflow-y:auto">
    <el-form
      ref="form"
      :model="storeForm"
      v-if="storeVisibleDialog"
      label-width="300px"
      label-position="right"
      style="padding: 0 30px 60px"
      class="form"
      :disabled="isOnlyShow"
      v-loading="loading">
      <el-form-item label="门店编号">
        <el-input v-model="storeForm.shopNumber" disabled></el-input>
      </el-form-item>
      <el-form-item label="门店名称">
        <el-input v-model="storeForm.shopName"></el-input>
        <div class="update-box" v-if="isShowForm(uploadForm.shopName)">
          <span>修改至</span>
          <span>{{ uploadForm.shopName }}</span>
        </div>
      </el-form-item>
      <el-form-item label="地区">
        <el-input v-model="storeForm.area"></el-input>
        <div class="update-box" v-if="isShowForm(uploadForm.area)">
          <span>修改至</span>
          <span>{{ uploadForm.area }}</span>
        </div>
      </el-form-item>
      <el-form-item label="街道">
        <el-input v-model="storeForm.street"></el-input>
        <div class="update-box" v-if="isShowForm(uploadForm.street)">
          <span>修改至</span>
          <span>{{ uploadForm.street }}</span>
        </div>
      </el-form-item>
      <el-form-item label="楼层">
        <el-input v-model="storeForm.shopFloor"></el-input>
        <div class="update-box" v-if="isShowForm(uploadForm.shopFloor)">
          <span>修改至</span>
          <span>{{ uploadForm.shopFloor }}</span>
        </div>
      </el-form-item>
      <el-form-item label="铺号">
        <el-input v-model="storeForm.shopFloorNumber"></el-input>
        <div class="update-box" v-if="isShowForm(uploadForm.shopFloorNumber)">
          <span>修改至</span>
          <span>{{ uploadForm.shopFloorNumber }}</span>
        </div>
      </el-form-item>

      <el-form-item label="渠道性质">
        <required-module-form-item-select
          label="渠道性质"
          type="store_type"
          :showLabel="false"
          :clearable="false"
          v-model="storeForm.shopType"
          @changeSelectedItem="
            e => {
              shopTypeSelectedItem = e
              storeForm.shopTypeName = e.content
            }
          "
          @onChangeItem="changeShopType" />
        <div class="update-box" v-if="isShowForm(uploadForm.shopType)">
          <span>修改至</span>
          <required-module-form-item-select
            label="渠道性质"
            type="store_type"
            placeholder=""
            :showLabel="false"
            v-model="uploadForm.shopType" />
        </div>
      </el-form-item>
      <el-form-item label="渠道性质二级分类（商场名称）">
        <required-module-form-item-select
          v-if="
            ['SJ007', 'SJ001', 'SJ009', 'SJ010', 'SJ008'].includes(
              storeForm.shopType
            )
          "
          class="formItem"
          v-model="storeForm.shopTypeValue"
          :showLabel="false"
          label="渠道性质二级分类"
          type="secondary_store_type"
          valueKey="content"
          :getDataListParams="{
            parentType: 'store_type'
          }"
          :parent-code="storeForm.shopType"
          @onChangeItem="
            e => {
              if (!e) {
                storeForm.marketName = ''
                storeForm.marketType = ''
              }
            }
          " />
        <RequiredFormItemInput
          v-else-if="storeForm.shopType === 'SJ005'"
          v-model="storeForm.shopTypeValue"
          class="formItem"
          label="渠道性质二级分类"
          :showLabel="false"
          :disabled="!storeForm.shopType" />
        <required-form-item-input
          v-else
          class="formItem"
          label="渠道性质二级分类"
          :showLabel="false"
          v-model="storeForm.shopTypeValue"
          :disabled="true" />

        <div class="update-box" v-if="isShowForm(uploadForm.shopTypeValue)">
          <span>修改至</span>
          <required-module-form-item-select
            v-if="
              ['SJ007', 'SJ001', 'SJ009', 'SJ010', 'SJ008'].includes(
                storeForm.shopType
              )
            "
            class="formItem"
            v-model="uploadForm.shopTypeValue"
            :showLabel="false"
            label="渠道性质二级分类"
            placeholder=""
            type="secondary_store_type"
            valueKey="content"
            :getDataListParams="{
              parentType: 'store_type'
            }"
            :parent-code="storeForm.shopType" />
          <RequiredFormItemInput
            v-else-if="storeForm.shopType === 'SJ005'"
            v-model="uploadForm.shopTypeValue"
            class="formItem"
            label="渠道性质二级分类"
            placeholder=""
            :showLabel="false"
            :disabled="!storeForm.shopType" />
          <required-form-item-input
            v-else
            class="formItem"
            label="渠道性质二级分类"
            placeholder=""
            :showLabel="false"
            v-model="uploadForm.shopTypeValue"
            :disabled="true" />
        </div>
      </el-form-item>
      <el-form-item label="卖场名称">
        <RequiredFormItemSelect
          v-if="!['SJ001', 'SJ008'].includes(storeForm.shopType)"
          v-model="storeForm.marketName"
          class="formItem"
          label="卖场名称"
          :dataList="marketNameSelectedItem"
          value-key="content"
          label-key="content"
          :showLabel="false"
          :required="false"
          :clearable="true"
          @onChangeItem="
            e => {
              storeForm.marketType = e ? e.type : ''
            }
          " />
        <RequiredModuleFormItemSelect
          v-else
          v-model="storeForm.marketName"
          class="formItem"
          :getDataListParams="{
            parentType: 'secondary_store_type',
            explainJson: storeForm?.authorizedCityName
          }"
          label="卖场名称"
          value-key="content"
          type="dealer_shop.market_name"
          :parent-content="storeForm.shopTypeValue"
          :showLabel="false"
          :required="false"
          @changeSelectedItem="
            e => {
              storeForm.marketType = e ? e.typeDetail : ''
            }
          " />
        <div class="update-box" v-if="isShowForm(uploadForm.marketName)">
          <span>修改至</span>
          <RequiredFormItemSelect
            v-if="!['SJ001', 'SJ008'].includes(storeForm.shopType)"
            v-model="uploadForm.marketName"
            class="formItem"
            label="卖场名称"
            placeholder=""
            :dataList="marketNameSelectedItem"
            value-key="content"
            label-key="content"
            :showLabel="false"
            :required="false"
            :clearable="true" />
          <RequiredModuleFormItemSelect
            v-else
            v-model="uploadForm.marketName"
            class="formItem"
            :getDataListParams="{
              parentType: 'secondary_store_type',
              explainJson: storeForm?.authorizedCityName
            }"
            label="卖场名称"
            placeholder=""
            value-key="content"
            type="dealer_shop.market_name"
            :parent-content="storeForm.shopTypeValue"
            :showLabel="false"
            :required="false" />
        </div>
      </el-form-item>
      <el-form-item label="卖场类型">
        <el-input v-model="storeForm.marketType" disabled></el-input>
        <div class="update-box" v-if="isShowForm(uploadForm.marketType)">
          <span>修改至</span>
          <!-- <span>{{ uploadForm.marketType }}</span> -->
          <el-input v-model="uploadForm.marketType" disabled></el-input>
        </div>
      </el-form-item>
      <el-form-item label="经营模式">
        <required-module-form-item-select
          label="经营模式"
          type="management_model"
          :showLabel="false"
          :clearable="false"
          v-model="storeForm.managementModel" />
        <div class="update-box" v-if="isShowForm(uploadForm.managementModel)">
          <span>修改至</span>
          <required-module-form-item-select
            label="经营模式"
            type="management_model"
            placeholder=""
            :showLabel="false"
            v-model="uploadForm.managementModel" />
        </div>
      </el-form-item>
      <el-form-item label="二级分销商">
        <el-input v-model="storeForm.contactPerson"></el-input>
        <div class="update-box" v-if="isShowForm(uploadForm.contactPerson)">
          <span>修改至</span>
          <span>{{ uploadForm.contactPerson }}</span>
        </div>
      </el-form-item>
      <el-form-item label="二级分销商身份证">
        <el-input v-model="storeForm.contactPersonIdcard"></el-input>
        <div
          class="update-box"
          v-if="isShowForm(uploadForm.contactPersonIdcard)">
          <span>修改至</span>
          <span>{{ uploadForm.contactPersonIdcard }}</span>
        </div>
      </el-form-item>
      <el-form-item
        label="二级分销商联系方式"
        prop="contactPersonPhone"
        :rules="[
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ]">
        <el-input v-model="storeForm.contactPersonPhone"></el-input>
        <div
          class="update-box"
          v-if="isShowForm(uploadForm.contactPersonPhone)">
          <span>修改至</span>
          <span>{{ uploadForm.contactPersonPhone }}</span>
        </div>
      </el-form-item>
      <el-form-item label="门店位置等级">
        <RequiredFormItemSelect
          class="formItem"
          v-model="storeForm.shopPositionLevel"
          :dataList="shopPositionLevelList"
          value-key="content"
          label-key="content"
          label="门店位置等级"
          :showLabel="false" />
        <div class="update-box" v-if="isShowForm(uploadForm.shopPositionLevel)">
          <span>修改至</span>
          <RequiredFormItemSelect
            class="formItem"
            v-model="uploadForm.shopPositionLevel"
            :dataList="shopPositionLevelList"
            label="门店位置等级"
            value-key="content"
            label-key="content"
            placeholder=""
            disabled
            :showLabel="false" />
        </div>
      </el-form-item>
      <el-form-item label="门店面积">
        <el-input
          type="number"
          v-model="storeForm.shopArea"
          maxlength="5"
          placeholder="请输入门店面积"></el-input>
        <div class="update-box" v-if="isShowForm(uploadForm.shopArea)">
          <span>修改至</span>
          <span>{{ uploadForm.shopArea }}</span>
        </div>
      </el-form-item>
      <el-form-item label="开业时间">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="storeForm.openingTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
        <div class="update-box" v-if="isShowForm(uploadForm.openingTime)">
          <span>修改至</span>
          <span>{{ uploadForm.openingTime }}</span>
        </div>
      </el-form-item>
      <el-form-item label="最新装修时间">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="storeForm.decorationTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
        <div class="update-box" v-if="isShowForm(uploadForm.decorationTime)">
          <span>修改至</span>
          <span>{{ uploadForm.decorationTime }}</span>
        </div>
      </el-form-item>
      <el-form-item label="装修到期时间规则类型">
        <required-module-form-item-select
          class="formItem"
          v-model="storeForm.decorationStopType"
          :showLabel="false"
          type="dealer_shop.decoration_stop_type"
          label="装修到期时间规则类型" />
        <div
          class="update-box"
          v-if="isShowForm(uploadForm.decorationStopType)">
          <span>修改至</span>
          <required-module-form-item-select
            class="formItem"
            v-model="uploadForm.decorationStopType"
            :showLabel="false"
            type="dealer_shop.decoration_stop_type"
            placeholder=""
            label="装修到期时间规则类型" />
        </div>
      </el-form-item>
      <el-form-item
        label="装修到期时间"
        v-if="storeForm.decorationStopType == 2">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="storeForm.decorationStopTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
        <div class="update-box" v-if="isShowForm(uploadForm.decorationTime)">
          <span>修改至</span>
          <span>{{ uploadForm.decorationTime }}</span>
        </div>
      </el-form-item>
      <el-form-item label="装修风格">
        <RequestModuleSelect
          v-model="storeForm.renovationStyle"
          type="decorate_style" />
        <div class="update-box" v-if="isShowForm(uploadForm.renovationStyle)">
          <span>修改至</span>
          <RequestModuleSelect
            :value="uploadForm.renovationStyle"
            placeholder=""
            type="decorate_style" />
        </div>
      </el-form-item>
      <el-form-item label="门店状态说明">
        <el-select
          v-model="storeForm.statusExplain"
          placeholder="请选择状态说明"
          @change="
            data => {
              if (data > 40) {
                storeForm.shopStatus = 1
              } else {
                storeForm.shopStatus = 0
              }
            }
          ">
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in statusExplainOptions"
            :key="index">
          </el-option>
        </el-select>

        <div class="update-box" v-if="isShowForm(uploadForm.statusExplain)">
          <span>修改至</span>
          <el-select
            v-model="uploadForm.statusExplain"
            placeholder="请选择状态说明">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in statusExplainOptions"
              :key="index">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="实际撤店时间">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="storeForm.actualWithdrawTime"
          type="date"
          placeholder="选择撤店时间">
        </el-date-picker>
        <div
          class="update-box"
          v-if="isShowForm(uploadForm.actualWithdrawTime)">
          <span>修改至</span>
          <span>{{ uploadForm.actualWithdrawTime }}</span>
        </div>
      </el-form-item>
      <el-form-item label="撤店原因">
        <RequestModuleSelect
          v-model="storeForm.actualWithdrawReason"
          type="shop_remove_code" />
        <div
          class="update-box"
          v-if="isShowForm(uploadForm.actualWithdrawReason)">
          <span>修改至</span>
          <RequestModuleSelect
            :value="uploadForm.actualWithdrawReason"
            placeholder=""
            type="shop_remove_code" />
        </div>
      </el-form-item>
      <el-form-item
        label="备注"
        prop="updateRemark"
        :rules="{ required: true, message: '请输入备注', trigger: 'blur' }">
        <el-input
          type="textarea"
          rows="5"
          v-model="storeForm.updateRemark"></el-input>
      </el-form-item>
      <!-- <el-form-item label="相关流程">
        <el-input type="text" v-model="storeForm.aboutFlow"></el-input>
      </el-form-item> -->
      <div
        v-if="showFooter"
        style="
          text-align: center;
          position: fixed;
          bottom: 0px;
          padding: 10px 0;
          width: 50%;
          background: #fff;
        ">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="storeVisibleDialog = false">取消</el-button>
      </div>
    </el-form>
  </el-drawer>
</template>

<script>
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import {
  getBossDataList,
  shopInfoDetail,
  authorizedcityListpage
} from '@/api/businessOos'

import { shopUpdateShopLog } from '../api'

import * as BusinessApi from '@/api/attractPlatform/business'
import { getStorage } from '@/utils/storage'
import moment from 'moment'

export default {
  props: {
    selectItem: {
      type: Object,
      default: () => ({})
    },
    updateDetailData: {
      type: Object,
      default: () => ({})
    },
    isOnlyShow: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  components: {
    RequiredModuleFormItemSelect,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequestModuleSelect
  },
  data() {
    return {
      marketNameSelectedItem: [],
      storeForm: {},
      uploadForm: {},
      shopTypeSelectedItem: null,
      position: {},
      statusExplainOptions: [
        { label: '待设计', value: '9' },
        { label: '设计中', value: '10' },
        { label: '装修中', value: '20' },
        { label: '装修完成', value: '30' },
        { label: '正常营业', value: '40' },
        { label: '装修失效', value: '50' },
        { label: '撤店失效', value: '60' },
        { label: '人工失效', value: '61' },
        { label: '转让失效', value: '70' }
      ],
      businessCountTotal: 0, // 售达方总数
      bossIdCountTotal: 0, // 实控人总数
      authorizedCityCodeTotal: 0, // 授权城市总数
      businessLicenseName: '', // 售达方名称
      idCard: 0, // 实控人身份证
      realName: '', // 实控人姓名
      selectionId: '',
      ifChildrenSupportingArea: '0', //是否儿童配套
      storeVisibleDialog: false,
      loading: false,
      shopPositionLevelList: [] //门店位置等级选择列表
    }
  },
  watch: {
    selectItem(val) {
      this.selectionId = val.id
      this.isOnlyShow ? this.getShopInfoDetail(val) : this.getShopInfoDetail()
    },
    updateDetailData(val) {
      if (val) {
        for (const key in val) {
          this.uploadForm[key] = val[key]
        }
      }
    }
  },
  computed: {
    isShowForm() {
      return data => {
        return data || data === ''
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeVisibleDialog')
      this.uploadForm = {}
    },

    async getShopInfoDetail(options) {
      this.loading = true
      let res = null
      if (options) {
        res = { data: options }
      } else {
        res = await shopInfoDetail({ id: this.selectionId })
      }
      this.loading = false
      this.$nextTick(() => {
        this.storeForm = res.data

        const timeId = setTimeout(() => {
          if (!['SJ001', 'SJ008'].includes(this.storeForm.shopType)) {
            //位置等级下拉框数据
            this.shopPositionLevelList = [
              { content: 'A1' },
              { content: 'A2' },
              { content: 'A3' }
            ]
          } else if (this.storeForm.shopType === 'SJ009') {
            this.shopPositionLevelList = [
              { content: '优位' },
              { content: '良位' }
            ]
          }
          clearTimeout(timeId)
        }, 500)
      })
    },
    onSubmit() {
      const userInfo = getStorage('userInfo')

      this.$refs.form.validate(valid => {
        if (!valid) {
          this.$message.error('请输入备注')
          return false
        }
        this.loading = true

        shopUpdateShopLog({
          ...this.storeForm,
          updateTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          updateUserName: userInfo?.realName,
          updateUserId: userInfo?.accountId
        })
          .then(res => {
            this.$message.success('保存成功')
            this.storeVisibleDialog = false
            this.$emit('onUpdateStore')
          })
          .finally(() => (this.loading = false))
      })
    },
    changeShopType(e) {
      this.storeForm.shopTypeValue = ''
      this.storeForm.shopPositionLevel = ''
      const shopTypeEmu = {
        SJ003: '独立店',
        SJ007: '新渠道店',
        SJ005: '展示店',
        SJ009: '购物中心店'
      }
      this.storeForm.marketType = shopTypeEmu[e?.code] || ''

      console.log(e?.code, shopTypeEmu[e?.code], this.storeForm.marketType)

      if (['SJ001', 'SJ008'].includes(e?.code)) {
        this.storeForm.marketName = ''
        //位置等级下拉框数据
        this.shopPositionLevelList = [
          { content: 'A1' },
          { content: 'A2' },
          { content: 'A3' }
        ]
      } else if (e?.code === 'SJ009') {
        this.shopPositionLevelList = [{ content: '优位' }, { content: '良位' }]
      } else {
        this.shopPositionLevelList = []
      }
      this.$nextTick(() => {
        if (!e) return
        if (!['SJ001', 'SJ008'].includes(e?.code)) {
          this.storeForm.marketName = `${
            this.storeForm?.authorizedCityName || ''
          }${this.shopTypeSelectedItem?.content || ''}`
        }

        this.storeForm.shopTypeValue = [
          'SJ008',
          'SJ001',
          'SJ009',
          'SJ010',
          'SJ005'
        ].includes(e?.code)
          ? ''
          : this.shopTypeSelectedItem?.content
      })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-form-item__content) {
  display: flex;
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
  .el-input {
    width: auto;
  }
  .el-textarea__inner {
    width: 250px;
  }
  & .update-box {
    display: flex;
    span {
      white-space: nowrap;
      padding: 0 10px;
    }
    span + span {
      color: red;
    }
  }
}
</style>
