<template>
  <required-form-item
    ref="formItemRef"
    class="required-form-item-input"
    v-bind="attrs.other"
    :class="{ strong, 'required-form-item-oa-input': isOA }"
    :required-trigger="requiredTrigger"
    :disabled="isDisabled"
    v-on="$listeners"
  >
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <slot name="contentBefore" />
    <el-input
      v-model="inputValue"
      :placeholder="placeholderText"
      :disabled="isDisabled"
      :title="inputValue"
      v-bind="attrs.input"
      v-on="listeners"
    />
  </required-form-item>
</template>

<script>
import requiredFormItem from './requiredFormItem'
import { FormItem } from 'element-ui'
import { mapGetters } from 'vuex'
import { setTimeout, clearTimeout } from 'timers'

const toCamelString = text => {
  const keyArr = text.split('-')
  let camelKey = keyArr[0]

  for (let i = 1, len = keyArr.length; i < len; i++) {
    camelKey += keyArr[i].replace(/^./, keyArr[i][0].toLocaleUpperCase())
  }
  return camelKey
}

export default {
  components: { requiredFormItem },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  inject: {
    bpmFlow: {
      default: undefined
    },
    oaFlow: {
      default: undefined
    }
  },
  props: {
    strong: Boolean,
    disabled: {
      type: Boolean,
      default: undefined
    },
    disabledPrior: {
      type: Boolean,
      default: undefined
    },
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    delayUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.value,
      requiredTrigger: ['blur']
    }
  },
  computed: {
    ...mapGetters('dealershipTransfer', ['isOA']),
    attrs({ $attrs }) {
      const formItemKeys = Object.keys(FormItem.props).concat(
        Object.keys(requiredFormItem.props)
      ).map(toCamelString)
      const input = {
        ...$attrs['inputProps'] || {},
        ...$attrs['input-props'] || {}
      }
      const other = {}
      const blackList = ['inputListeners', 'inputProps']
      for (const key in $attrs) {
        const camelKey = toCamelString(key)
        if (formItemKeys.includes(camelKey)) {
          other[key] = $attrs[key]
        } else if (!blackList.includes(camelKey)) {
          input[key] = $attrs[key]
        }
      }

      return {
        other,
        input
      }
    },
    listeners({ $listeners, $attrs }) {
      return Object.assign($listeners, $attrs['inputListeners'], $attrs['input-listeners'])
    },
    isDisabled({ disabledPrior, disabled, bpmFlow, oaFlow, isOA }) {
      if (disabledPrior !== undefined) {
        return disabledPrior
      }
      if (!bpmFlow && isOA) {
        return true
      }
      return oaFlow?.disabled || bpmFlow?.disabled || disabled
    },
    placeholderText({ disabledPrior, placeholder, isOA }) {
      if (isOA && disabledPrior === undefined) {
        if (['归档后生成', '自动生成', '自动带出'].includes(placeholder)) {
          return placeholder
        }
        return ''
      }
      return placeholder === null
        ? this.$attrs['label'] && `请填写${this.$attrs['label']}`
        : placeholder
    }
  },
  watch: {
    // 处理大表单卡顿问题, 但是使用延时则 change规则会失效,只能使用blur
    inputValue(value) {
      if (this.delayUpdate) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('changeValue', value)
        }, 500)
      } else {
        this.$emit('changeValue', value)
      }
    },

    value(value) {
      this.inputValue = value
      if (this.delayUpdate && this.$attrs.prop) {
        this.$refs.formItemRef.$formItemRef.validate('blur')
      }
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.strong {
  ::v-deep {
    .el-form-item__label {
      color: #ff4949 !important;
    }
  }
}
.required-form-item-input {
  ::v-deep {
    .el-input {
      .el-input__inner[type='number'] {
        padding-right: 0;
      }
    }
  }
}
.required-form-item-oa-input {
  ::v-deep {
    .el-input.is-disabled,
    .el-textarea.is-disabled {
      .el-input__inner,
      .el-textarea__inner {
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: rgba(0, 0, 0, 0.06);
        color: #333;
        cursor: default;
        /* padding-left: 0; */
      }
    }
  }
}
</style>
